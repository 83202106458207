import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { IconButton, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { EntityState } from "../../../../Common/api/models/EntityState";

interface ToolDeleteToggleButtonProps {
  entityName: string;
  entityStatus: EntityState;
  onClick: () => void;
  isLastItem?: boolean;
  preventLastItemRemoval?: boolean;
}

const EntityDeleteToggleButton = ({
  entityName,
  entityStatus,
  onClick,
  isLastItem = false,
  preventLastItemRemoval = false
}: ToolDeleteToggleButtonProps): JSX.Element => {
  const isRestore = useMemo(
    () => entityStatus === EntityState.Delete,
    [entityStatus]
  );

  return (
    <>
      <Tooltip
        title={
          isRestore ? `Restore ${entityName}` : (preventLastItemRemoval && isLastItem) ? `You need at least one tool` : `Mark ${entityName} to remove`
        }
        arrow
        placement="top"
      >
        <div style={{"display": "inline"}}>
          <IconButton aria-label="delete" onClick={onClick} disabled={(preventLastItemRemoval && isLastItem && !isRestore)}>
            {isRestore ? <RestoreFromTrashIcon /> : <DeleteOutlineIcon />}
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default EntityDeleteToggleButton;
