import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useForm } from "react-hook-form";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";

interface RemoveToolsPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  capabilityNames: string[];
  projectName: string;
}

const RemoveToolsPopup = ({
  open,
  handleClose,
  capabilityNames,
  projectName,
  handleSubmit,
}: RemoveToolsPopupProps): JSX.Element => {
  const form = useForm({ defaultValues: { name: "" }, mode: "onBlur" });
  const nameInput = form.watch("name");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      data-testid="removetoolspopup"
    >
      <DialogTitle>Do you want to remove this {capabilityNames.length > 1 ? "tools" : "tool"} from the project?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
        You are about to remove {capabilityNames.length > 1 ? "tools" : "tool"} ({capabilityNames.join(", ")}) from project {projectName}. <br/>
        This action can not be undone. <br/>
        To confirm this action, please type project name
        </DialogContentText>
        <HeiTextField control={form.control} label="Project Name" name="name" sx={{"width": "100%"}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="error" disabled={nameInput.trim() != projectName.trim()}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveToolsPopup;
