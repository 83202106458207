import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Link, generatePath } from "react-router-dom";
import routes from "../../../../Common/consts/routes";
import { ProjectDetails } from "../../api/models/ProjectDetails";
import { tabs } from "../../api/models/ProjectDetailTabs";
import ProjectCostsTab from "../../views/ProjectDetails/ProjectCostsTab/ProjectCostsTab";
import ProjectDetailsTab from "../../views/ProjectDetails/ProjectDetailsTab/ProjectDetailsTab";
import ProjectHistoryTab from "../../views/ProjectDetails/ProjectHistoryTab/ProjectHistoryTab";
import ProjectToolsTab from "../../views/ProjectDetails/ProjectToolsTab/ProjectToolsTab";
import ProjectUsersTab from "../../views/ProjectDetails/ProjectUsersTab/ProjectUsersTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`project-details-tabpanel-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

interface ProjectTabPanelsProps {
  project: ProjectDetails;
  routeTabIndex: number;
  userCanEdit: boolean;
}

const ProjectTabPanels = ({
  project,
  routeTabIndex,
  userCanEdit,
}: ProjectTabPanelsProps) => {
  const isArchived = project?.isArchived ?? false;
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            pl: "80px",
            background: "white",
          }}
        >
          {!isArchived && (
            <Tabs value={routeTabIndex}>
              {tabs.map((tab) => (
                <Link
                  key={tab}
                  to={generatePath(routes.projectDetails, {
                    id: project.id,
                    tab,
                  })}
                >
                  <Tab label={tab} />
                </Link>
              ))}
            </Tabs>
          )}
        </Box>
        <TabPanel value={routeTabIndex} index={0}>
          <ProjectDetailsTab project={project} userCanEdit={userCanEdit} />
        </TabPanel>
        <TabPanel value={routeTabIndex} index={1}>
          <ProjectToolsTab
            id={project.id}
            projectName={project.projectName}
            packageId={project.packageId}
            userCanEdit={userCanEdit && project.flaggingStatus !== "Locked"}
          />
        </TabPanel>
        <TabPanel value={routeTabIndex} index={2}>
          <ProjectUsersTab
            id={project.id}
            userCanEdit={userCanEdit && project.flaggingStatus !== "Locked"}
          />
        </TabPanel>
        <TabPanel value={routeTabIndex} index={3}>
          <ProjectCostsTab id={project.id} />
        </TabPanel>
        <TabPanel value={routeTabIndex} index={4}>
          <ProjectHistoryTab id={project.id} />
        </TabPanel>
      </Box>
    </>
  );
};

export default ProjectTabPanels;
