import { Backdrop, Box, CircularProgress } from "@mui/material";
import useProjectTools from "../../../hooks/useProjectTools";
import { useToolsForSelect } from "../../../../../Common/hooks/useToolsForSelect";
import ProjectToolsForm from "../../../components/ProjectToolsForm/ProjectToolsForm";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";
interface ProjectToolsTabProps {
  userCanEdit: boolean;
  id: string;
  packageId: string;
  projectName: string;
}

const ProjectToolsTab = ({
  id,
  packageId,
  userCanEdit,
  projectName
}: ProjectToolsTabProps) => {
  usePageTitle("Project | Tools");
  const { projectTools, isLoading: isLoadingProjectTools } =
    useProjectTools(id);
  const {
    optionalTools,
    tools: mandatoryTools,
    isLoading: isLoadingOptionalTools,
  } = useToolsForSelect(packageId);

  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingProjectTools || isLoadingOptionalTools}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {projectTools && (
        <ProjectToolsForm
          projectTools={projectTools}
          mandatoryTools={mandatoryTools}
          optionalTools={optionalTools}
          userCanEdit={userCanEdit}
          projectName={projectName}
        />
      )}
    </Box>
  );
};
export default ProjectToolsTab;
