import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import { useForm } from "react-hook-form";
import HeiTextField from "../../../../Common/components/Form/HeiTextField/HeiTextField";

interface SelfonboardingPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  projectName: string;
}

const RemoveProjectPopup = ({
  open,
  handleClose,
  projectName,
  handleSubmit,
}: SelfonboardingPopupProps): JSX.Element => {
  const form = useForm({ defaultValues: { name: "" }, mode: "onBlur" });
  const nameInput = form.watch("name");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      data-testid="removeprojectpopup"
    >
      <DialogTitle>Do you want to remove this project?</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          You are about to remove the project, please enter it's name to confirm. <br />
          This action can not be undone!
        </DialogContentText>
        <HeiTextField control={form.control} label="Project Name" name="name" sx={{"width": "100%"}}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="error" disabled={nameInput.trim() != projectName.trim()}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveProjectPopup;
