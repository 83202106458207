import _ from "lodash";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { EntityState } from "../../../Common/api/models/EntityState";
import {
  ProjectTool,
  ToolStatusEnum,
} from "../../../Common/api/models/ProjectTool";
import { ProjectFormType } from "../../../Common/hooks/useCreateProject";
import { ToolVisibility } from "../../../Common/models/toolVisibilityEnum";
import { ToolModel } from "../../CreateProject/models/ToolTile";
import {
  finalizeProjectsToolCreation,
  getProjectTools,
  putProjectTools,
} from "../api/projectDetailsHttpClient";
import { ProjectFinalizeFormType } from "./useProjectDetails";

const useProjectToolsForm = (projectTools: ProjectTool[]) => {
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [toolsToRemove, setToolsToRemove] = useState([] as string[]);
  const [toolsTableList, setToolsTableList] = useState(projectTools);
  const [isFinalizing, setIsFinalizing] = useState(false);
  
  const getFormTools = (tools: ProjectTool[]) => {
    return tools.map((pt) => ({
      inviteMode: pt.inviteMode,
      isHidden: pt.isHidden,
      ServiceNowGroup: pt.ServiceNowGroup,
      capabilityToolKey: pt.capabilityToolKey,
      capabilityId: pt.capabilityId,
      toolVisibility: pt.toolVisibility,
      toolId: pt.toolId,
      editStatus: EntityState.NotChanged,
    }));
  };

  const defaultValues = {
    tools: getFormTools(projectTools),
  } as ProjectFormType;

  const form = useForm<ProjectFormType>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });
  const { control, getValues } = form;

  const { append, remove } = useFieldArray({
    control,
    name: "tools",
  });

  const handleAdd = (tool: ToolModel) => {
    const inviteMode = tool.isUserAutoAdd ? "Automatically" : "Manually";

    const projectTool: ProjectTool = {
      toolName: tool.toolName,
      toolDisplayName: tool.toolDisplayName,
      status: ToolStatusEnum.New,
      capabilityId: tool.capabilityId,
      capabilityName: tool.capabilityName,
      customBillingMetric: 0,
      isHidden: false,
      inviteMode: inviteMode,
      toolVisibility: ToolVisibility.Private,
      optOut: false,
      projectId: "",
      ServiceNowGroup: "",
      toolId: "",
      capabilityToolKey: tool.capabilityToolKey,
      toolProjectDeeplink: "",
      toolProjectId: "",
      toolProjectName: "",
      CustomFields: tool.CustomFields,
      toolEditStatus: EntityState.Add,
      isToolNew: false,
      isManual: false,
    };
    append({
      inviteMode: inviteMode,
      ServiceNowGroup: "",
      editStatus: EntityState.Add,
      capabilityToolKey: tool.capabilityToolKey,
      capabilityId: tool.capabilityId,
      toolId: tool.toolId,
    });

    setToolsTableList([...toolsTableList, projectTool]);
  };

  const handleDelete = (index: number) => {
    if (toolsTableList[index].toolEditStatus == EntityState.Add) {
      setToolsTableList([
        ...toolsTableList.slice(0, index),
        ...toolsTableList.slice(index + 1),
      ]);
    }
    const toolEditStatus = form.getValues(
      `tools.${index}.editStatus`
    ) as EntityState;

    if (toolEditStatus === EntityState.Add) {
      remove(index);
      return;
    }

    const toolName = `${toolsTableList[index].toolName} - ${toolsTableList[index].toolDisplayName ?? toolsTableList[index].capabilityName}`;
    if (toolEditStatus === EntityState.Delete) {
      form.resetField(`tools.${index}`);
      setToolsToRemove(toolsToRemove.filter(t => {return t !== toolName}));
    } else {
      form.setValue(`tools.${index}.editStatus`, EntityState.Delete, {
        shouldDirty: true,
      });
      setToolsToRemove([...toolsToRemove, toolName]);
      
    }
  };

  const markToolStatus = (
    tools: Record<string, any>[],
    defaultFormTools: any[]
  ) =>
    tools.map((t) => {
      if (
        t.editStatus === EntityState.Delete ||
        t.editStatus === EntityState.Add
      ) {
        return { ...t };
      }

      const defaultValueTool = defaultFormTools.filter(
        (dt) => dt.toolId === t.toolId
      )[0];
      const hasChanged =
        defaultValueTool.isHidden !== t.isHidden ||
        defaultValueTool.inviteMode !== t.inviteMode ||
        defaultValueTool.toolVisibility !== t.toolVisibility ||
        defaultValueTool.ServiceNowGroup !== t.ServiceNowGroup;

      return {
        ...t,
        editStatus: hasChanged ? EntityState.Update : EntityState.NotChanged,
      };
    });

    const handleFinalize = async (values: ProjectFinalizeFormType) => {
      setIsFinalizing(true);
      try {
        await finalizeProjectsToolCreation(id!, values.toolId, values.capabilityId, values.toolProjectId, values.toolProjectDeeplink);
        enqueueSnackbar("Project has been updated", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        navigate(0);
      } catch {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } finally {
        setIsFinalizing(false);
      }
    }

  const handleSubmit = async () => {
    const tools = form.getValues("tools");
    const defaultFormTools = form.formState.defaultValues?.tools as any[];

    const markedTools = markToolStatus(tools, defaultFormTools);
    const groupedTools = _.groupBy(markedTools, "editStatus");

    setIsSaving(true);

    try {
      await putProjectTools(id!, groupedTools);
      queryClient.invalidateQueries(["project_tools", id!]);

      const newProjectTools = await getProjectTools(id!);
      setToolsTableList(newProjectTools);
      form.reset({
        tools: getFormTools(newProjectTools),
      } as ProjectFormType);
      setToolsToRemove([]);

      enqueueSnackbar("Tools have been updated!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch {
      enqueueSnackbar("Tools have not been updated!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.error("Something went wrong");
    } finally {
      setIsSaving(false);
    }
  };
  return {
    form,
    getValues,
    handleDelete,
    handleFinalize,
    handleAdd,
    handleSubmit,
    isSaving,
    isFinalizing,
    toolsTableList,
    setToolsTableList,
    toolsToRemove
  };
};

export default useProjectToolsForm;
