import { Alert, Button, Typography } from "@mui/material";
import * as React from "react";
import {
  UseFieldArrayAppend,
  useForm,
  UseFormGetValues,
} from "react-hook-form";
import { useSearchUser } from "../../../Projects/CreateProject/hooks/useSearchUser";
import ToolAccess from "../../../Projects/ProjectDetails/components/ToolAccess/ToolAccess";
import { EntityState } from "../../api/models/EntityState";
import { ProjectFormType } from "../../hooks/useCreateProject";
import { User } from "../../models/FormModels";
import { Roles } from "../../models/roles";
import { RolesForSelect } from "../../models/RolesForSelect";
import { UserSearch } from "../../models/UserSearch";
import HeiTextField from "../Form/HeiTextField/HeiTextField";
import HeiSelection from "../HeiSelection/HeiSelection";
import HeiUserAutocomplete from "./HeiUserAutocomplete";

const defaultValuesUserFromAD: User & {
  isInProject: boolean;
} = {
  name: "",
  adPrinciple: "",
  email: "",
  roles: [],
  toolCaps: [],
  isInProject: false,
  editStatus: EntityState.NotChanged,
};

export const emailRegex =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

interface SearchAndAddUserSectionProps {
  addUser: UseFieldArrayAppend<ProjectFormType, "users">;
  getUsers: UseFormGetValues<ProjectFormType>;
  searchWidth?: string;
  customFilterParams?: string;
  formDefaultValues: ProjectFormType;
  hideToolAccess?: boolean;
}

function ActionButtons(props: {
  onAddClick: () => Promise<void>;
  onCancelClick: () => void;
}) {
  return (
    <div style={{ display: "flex" }}>
      <Button
        disabled={false}
        sx={{ height: 40, marginRight: "8px" }}
        variant="contained"
        onClick={props.onAddClick}
        size={"small"}
      >
        ADD
      </Button>
      <Button sx={{ height: 40 }} onClick={props.onCancelClick} size={"small"}>
        CANCEL
      </Button>
    </div>
  );
}

const SearchAndAddUserSection = ({
  addUser,
  getUsers,
  searchWidth,
  customFilterParams,
  formDefaultValues,
  hideToolAccess,
}: SearchAndAddUserSectionProps): JSX.Element => {
  const autoComplete = React.useRef<HTMLInputElement>(null);
  const [newAccountCreation, setNewAccountCreation] = React.useState(false);
  const [missingEmail, setMissingEmail] = React.useState(false);
  const [userSelected, setUserSelected] = React.useState(false);
  const [user, setUser] = React.useState<UserSearch>(defaultValuesUserFromAD);
  const { getUsersFromAd } = useSearchUser(customFilterParams ?? "");

  const form = useForm({
    defaultValues: formDefaultValues ?? { users: [defaultValuesUserFromAD] },
    mode: "onBlur",
  });
  const { control, getValues, reset, trigger } = form;

  const addUserToTable = async () => {
    const valid = await trigger();
    if (!valid) {
      return;
    }

    const formUser = getValues().users[0] as User;

    const userToAdd: User = {
      name: newAccountCreation ? formUser.name : user.name,
      adPrinciple: user.adPrinciple,
      email: missingEmail || newAccountCreation ? formUser.email : user.email,
      roles: formUser.roles,
      toolCaps: formUser.toolCaps,
      editStatus: EntityState.Add,
    };
    addUser(userToAdd);

    resetAddingUser();
  };

  const handleChange = (selectedUser: UserSearch) => {
    selectedUser ? setUser(selectedUser) : setUser(defaultValuesUserFromAD);
    setUserSelected(true);
    setMissingEmail(selectedUser.email === null);
  };

  const resetAddingUser = () => {
    setUserSelected(false);
    setMissingEmail(false);
    setNewAccountCreation(false);
    setUser(defaultValuesUserFromAD);
    reset();
    if (autoComplete.current) {
      const clearButton = autoComplete.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0] as HTMLElement;
      clearButton.click();
    }
  };

  const handleCreateNewAccount = () => {
    setNewAccountCreation(true);
    setUserSelected(true);
  };

  async function validateExistingUser(value: string | any) {
    const users = getUsers("users") as User[];
    const emailAddedLocaly = users.some(
      (userInTable) => userInTable.email.toLowerCase() === value.toLowerCase()
    );
    if (emailAddedLocaly) return "User is already in project";

    const apiUsers = await getUsersFromAd(value.trim());
    const emailAdded = apiUsers
      .filter((u) => u.isInProject)
      .some(
        (userInTable) => userInTable.email.toLowerCase() === value.toLowerCase()
      );
    return emailAdded ? "User is already in project" : undefined;
  }

  function validateRoles() {
    return (value: string[] | any) => {
      if (value !== undefined) {
        const poId = Roles["Product Owner"];
        const users = getUsers("users") as User[];
        const po = users.some((userInTable) =>
          userInTable.roles.some((role) => role.toString() === poId)
        );
        const hasPO = value.some(
          (selectedRoles: string) => selectedRoles === poId
        );
        return po && hasPO ? "Only one PO allowed" : true;
      }
      return false;
    };
  }

  return (
    <div>
      {newAccountCreation && (
        <div style={{ width: "100%" }}>
          <Alert
            variant="outlined"
            severity="warning"
            sx={{ background: "rgba(255, 162, 80, 0.1)", mb: "20px" }}
          >
            Are you sure this user doesn’t have an account yet or is new to
            Heineken? If you add them as a guest, the new guest account is
            automatically created.
          </Alert>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          {newAccountCreation ? (
            <HeiTextField
              control={control}
              name={`users.0.name`}
              label="Name"
              size="small"
              placeholder="Enter name"
              sx={{ width: "50%", display: "flex", marginRight: "8px" }}
              rules={{
                required: "Name is required",
                pattern: {
                  value: /^[a-z ,.'-]+$/i,
                  message: "Enter valid user name",
                },
              }}
            />
          ) : (
            <HeiUserAutocomplete
              addUser={addUser}
              getUsers={getUsers}
              handleChange={handleChange}
              handleCreateNewAccount={handleCreateNewAccount}
              autoComplete={autoComplete}
              width={searchWidth}
              customFilterParams={customFilterParams}
            />
          )}
          {userSelected && (
            <>
              {(missingEmail || newAccountCreation) && (
                <HeiTextField
                  name={"users.0.email"}
                  control={control}
                  label={"Email"}
                  size="small"
                  placeholder="Enter email"
                  sx={{ width: "40%", marginRight: "8px" }}
                  rules={{
                    required:
                      "No corresponding email was found. Please enter an email where this team member can be reached.",
                    pattern: {
                      value: emailRegex,
                      message: "Enter valid email",
                    },
                    validate: {
                      userEmailValid: validateExistingUser,
                    },
                  }}
                />
              )}
              <HeiSelection
                control={control}
                fieldName={"users.0.roles"}
                getValues={getValues}
                options={RolesForSelect}
                label={"Roles"}
                multiple={true}
                rules={{
                  required: "Please select at least 1 role for this user.",
                  validate: validateRoles(),
                }}
              />
              <ActionButtons
                onAddClick={async () => await addUserToTable()}
                onCancelClick={() => resetAddingUser()}
              />
            </>
          )}
        </div>
        {!hideToolAccess && (
          <div style={{ padding: "8px" }}>
            <Typography variant="body2" sx={{ color: "#202020", mb: 1, mt: 1 }}>
              Set tool access
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Select a tool to send an invite to this user or deselect a tool to
              revoke access.
            </Typography>
            {(formDefaultValues.users[0] as User).toolCaps?.map((st, i) => {
              return (
                <ToolAccess
                  key={i}
                  tool={st}
                  toolIndex={i}
                  userIndex={0}
                  form={form}
                  userCanEdit={true}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchAndAddUserSection;
