import { Box, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { CapabilityTool, toolOrder } from "../../../../Common/models/tools";
import { orderTools } from "../../../../Common/utils/toolUtil";
import GridTools from "../../../ProjectsList/components/GridTools/GridTools";

const MAX_CHARS = 100;
const TooltipContent = ({ content }: { content: string }) => (
  <Box
    component="pre"
    style={{ whiteSpace: "pre-wrap", margin: 0, fontFamily: "inherit" }}
  >
    {content}
  </Box>
);
export const getProjectHistoryColumns = (): GridColDef[] => [
  {
    field: "executedOn",
    headerName: "Executed On",
    type: "date",
    flex: 0.5,
    renderCell: (params: GridRenderCellParams<string>) => {
      const calculationDate = params.row.executedOn;
      const formattedDate = dayjs(calculationDate).format(
        "DD-MM-YYYY HH:mm:ss"
      );

      return <span>{formattedDate}</span>;
    },
  },
  {
    field: "executedBy",
    headerName: "Executed By",
    flex: 0.5,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 0.5,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "value",
    headerName: "Value",
    flex: 1.2,
    minWidth: 250,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams<string>) => {
      const fullText = params.value as string;
      const truncatedText =
        fullText.length > MAX_CHARS
          ? `${fullText.substring(0, MAX_CHARS)}...`
          : fullText;

      return (
        <Tooltip
          title={<TooltipContent content={fullText} />}
          placement="top-start"
        >
          <span
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: 1.5,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {truncatedText}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: "tools",
    headerName: "Tools",
    disableColumnMenu: true,
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams<string>) => {
      const toolsString = params.row.tools as string[];
      const tools: CapabilityTool[] = toolsString.map(
        (ts) => toolOrder.find((t) => t.endsWith(ts))!
      );

      return <GridTools tools={orderTools(tools)} displayCount={10} />;
    },
  },
];
