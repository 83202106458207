import { Box, Typography } from "@mui/material";
import usePageTitle from "../../../../../Common/hooks/usePageTitle";
import ProjectHistoryGrid from "../../../components/ProjectHistoryGrid/ProjectHistoryGrid";

interface ProjectHistoryTabProps {
  id: string;
}

const ProjectHistoryTab = ({ id }: ProjectHistoryTabProps): JSX.Element => {
  usePageTitle("Project | History");

  return (
    <Box sx={{ pl: "80px", pt: "24px", height: "100%", width: "90%" }}>
      <Typography variant={"h6"}>History</Typography>
      <Typography variant={"body2"} sx={{ marginBottom: "16px" }}>
        Overview of the project history.
      </Typography>

      <ProjectHistoryGrid id={id} />
    </Box>
  );
};

export default ProjectHistoryTab;
